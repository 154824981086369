import CasTokenStrategy from '@/services/auth/tokenStrategies/casTokenStrategy';
import { casEnabled, bootstrap } from '@/services/auth/cas_service';
import '@hotmart-org-ca/cosmos/dist/loading';
import { hotmartDomainsUrls, isDomainRebrand } from '@/shared/configs/rebrand_urls';

const publicRoutes = ['/auth/surrogate', '/auth/logout', '/auth/start-logout'];

function importMainJs() {
  import('./bootstrap');
}

if (!isDomainRebrand) {
  const url = window.location.href.replace(window.location.origin, hotmartDomainsUrls.send_client_domain);
  window.location.href = url;
} else if (casEnabled() && !publicRoutes.includes(window.location.pathname)) {
  bootstrap.subscribe({
    next: async (casUser) => {
      new CasTokenStrategy().setToken(casUser.id_token);

      importMainJs();
    },
  });

  const bootstrapConfig = {
    appName: 'saas-send-client',
    onRedirect: (url) => {
      import('./bootstrap').then(({ navigateTo }) => {
        navigateTo(url);
      });
    },
  };
  bootstrap.start(bootstrapConfig);
} else {
  importMainJs();
}
